#barcode-content{
}

#labelContentToPrint{
    border: 1px solid black;
    display: flex;
    flex-direction: column;
}

#header-card{
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 1px solid;
}
#logo{

}
#header{

}
#barcode{

}

/* @media print {
    *{
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important; 
        text-shadow: none !important;
    }
    #barcode-content{
        border: 2px solid black;
    }
    
    #labelContentToPrint{
       
        display: flex;
        flex-direction: column;
    }
    
    #header-card{
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        border-bottom: 1px solid;
    }
    #logo{
    
    }
    #header{
    
    }
    #barcode{
    
    }
} */