/* Header */

body {
  color: #212529;
  font-size: 13px;
  background-color: #f2f3f8;
}
.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.header {
  background-color: #1a1a27;
  min-height: 65px;
  border: 0px;
  padding: 6px 0 0 0;
}
#logo-header {
  margin-left: -8px;
}
.right-menu {
  right: 1px;
  margin-right: 65px;
  position: absolute;
  top: 15px;
}
#nav-user {
  margin: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 3px 0px 3px 3px;
}
#nav-user i {
  color: #a2a5b9;
  margin-right: 10px;
}
#nav-user .dropdown-item {
  color: #858586;
  font-size: 13px;
}
#nav-user .dropdown-item:hover {
  color: #212529;
}
#menu-toggle-2 {
  font-size: 20px;
  font-weight: 1200;
  color: #757575;
  margin: 11px 0px 19px 0;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}
.navbar-toggler {
  padding: 0.25rem 0.45rem;
}
.nav-link {
  padding: 0;
}
.sub-header {
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  margin-bottom: 12px;
  min-width: 350px;
  height: 65px;
}

.sub-header h1 {
  color: #4e565f;
}

h1 {
  font-size: 16px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}

/* Toggle Styles */
.leftNavBar {
  padding-top: 2px;
  padding-bottom: 2px;
}
.nav-pills > li > a {
  border-radius: 0;
}
.nav-pills > li.active > a {
  background: #1a1a27;
}
.navbar-sidebar-toggler {
  margin-right: 9px;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid #d0d1d2;
  border-radius: 0.25rem;
}
.navbar-sidebar-toggler:hover {
  cursor: pointer;
}
#navbarNavDropdown {
  margin-right: 60px;
}
#navbarNavDropdown .dropdown-item {
  font-size: 13px;
  color: #858586;
}
#navbarNavDropdown .dropdown-item:hover {
  font-size: 13px;
  color: #212529;
}

#menu {
  margin-top: 80px;
}
#menu hr {
  border-top: 2px solid #313646;
}
#menu li {
  color: #4e565f;
  list-style: none;
}
#menu li ul {
  padding-inline-start: 12px;
}
#menu li ul li a i {
  font-size: 12px;
}
#menu li ul li {
  text-indent: 6px;
}
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
  margin-top: 0px;
  background: #f2f3f8;
}

#wrapper.toggled {
  padding-left: 250px;
  overflow: hidden;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  top: 0;
  bottom: 1px;
  /* padding-top: 300px; */

  margin-left: -250px;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #1e1e2d;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#sidebar-wrapper:hover {
  overflow-y: scroll;
}

/*
 *  STYLE 4
 */

#sidebar-wrapper::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px #3a3a49; */
  background-color: #555d75;
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
/* ::-webkit-scrollbar-thumb {
  height: 50px;
} */
/* #sidebar-wrapper::-webkit-scrollbar-thumb {
  background-color: #131323;
  border: 1px solid #131323;
  border-radius: 3px;
  height: 5px !important;
} */
.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #acacac;
  border-radius: 50px;
}
/* #sidebar-wrapper::-webkit-scrollbar-thumb:vertical {
  background-color: rgb(142, 142, 142);
  -webkit-border-radius: 0px;
  -webkit-width: 5;
  height: 100px;
} */
#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  position: absolute;
  padding: 15px;
  width: 100%;
  /* overflow-x: hidden; */
  min-height: 980px;
}
#page-content-wrapper a {
  margin: 0 6px 0 0;
  /* padding: 8px 12px; */
}
#page-content-wrapper i {
  margin: 0;
}

.xyz {
  min-width: 360px;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0px;
}

.fixed-brand {
  width: auto;
}
/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px;
}

.sidebar-nav li {
  text-indent: 15px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #a2a3b7;
  /* #999999; */
}

.sidebar-nav li .active-now {
  display: block;
  text-decoration: none;
  color: #3c9b69;
  background-color: #bee9db !important;
  font-weight: 500;
}

.sidebar-nav li a i {
  color: #434d6b;
  font-size: 16px;
  /* #999999; */
}

.sidebar-nav li .active-now i {
  color: #3c9b69;
  font-size: 16px;
  background-color: #bee9db;
  /* #999999; */
}

.sidebar-nav li a.active-now:hover {
  text-decoration: none;
  color: rgb(4, 105, 13);
  background-color: #bee9db;
  /* border-left: red 2px solid; */
}
.arrow-icon {
  margin-right: 13px;
  margin-top: 7px;
}
.arrow-icon i {
  font-size: 10px !important;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: #1a1a27;
  /* border-left: red 2px solid; */
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
  background-color: #1a1a27;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: #1a1a27;
}

.no-margin {
  margin: 0;
}
.pageContent {
  padding: 16px;
  width: 100%;
  min-width: 350px;
}

.badge-00open {
  min-width: 37px;
  background-color: #abadaf !important;
}
.badge-05assign {
  min-width: 37px;
  background-color: #77b5fc !important;
}
.badge-10wip {
  min-width: 37px;
  background-color: #ffc107 !important;
}
.badge-15hold {
  min-width: 37px;
  background-color: #fc8777 !important;
}
.badge-20dev {
  min-width: 37px;
  background-color: #d896eb !important;
}
.badge-25test {
  min-width: 37px;
  background-color: #add329 !important;
}
.badge-30done {
  min-width: 37px;
  background-color: #5add90 !important;
}

.badge-low,
.badge-API {
  background-color: #77b5fc !important;
}
.badge-wip,
.badge-high,
.badge-Cloud {
  background-color: #c999dc !important;
}

.badge-done,
.badge-normal,
.badge-UI {
  background-color: #74d89a !important;
}
.badge-hold,
.badge-bottleNeck,
.badge-DB {
  background-color: #ccb671 !important;
}
.badge-critical,
.badge-PM {
  background-color: #ffc107 !important;
}

.panel-default > .panel-heading {
  background-color: #fff;
  min-height: 50px;
  border-bottom: 1px solid #f2f3f8;
}
.panel-default {
  /* border-color: #F2F3F8; */
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
}
.panel-body {
  /* padding: 25px; */
  background-color: #fff;
}

.nav-pills .nav-link {
  margin-right: 8px;
}
.nav-pills .nav-link:hover,
.nav-pills .nav-link.active:hover {
  background-color: #bcbedb;
}

.nav-pills .nav-link.active {
  background-color: #eff0ff;
  color: #1d61aa;
  font-weight: 600;
}

.sub-header .dropdown-icon {
  background-color: #bee9db;
  color: #595d6e;
  font-size: 16px;
  border-radius: 3px !important;
}

.sub-header .dropdown-icon:focus {
  box-shadow: 0 0 0 0.1rem rgb(95, 206, 147);
}
.sub-header .dropdown-icon .fa {
  color: #56bc86;
  margin: 0px;
}

.table .dropdown-icon .fa {
  margin: 6px;
}

.sub-header .dropdown-item,
.table .dropdown-item {
  color: #595d6e;
  font-size: 13px;
}
.fixTableHead {
  overflow-y: auto;
  max-height: 650px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}



.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #9498a7;
  padding: 7px 30px;
  text-align: right;
  /* position: relative; */
  position: relative;

  display: block;
  margin-top: 30px;
  /* z-index: 9999; */
}
.footWrap {
  min-height: 30px;
  margin-top: 30px;
}

.fa-stack {
  line-height: 2.3em;
}

.btn-primary {
  background-color: #2e90ff;
  border-color: rgb(33, 126, 233);
}

.btn-success {
  background-color: #03b931;
  border-color: #039227;
}
button.active {
  box-shadow: 1px 1px 18px #b708e2;
}
.draggable {
  position: absolute;
  z-index: 1051;
  right: 0;
  top: 75px;
  /* top: 110px;
  left: 50px; */
}
.myTableDrag {
  position: absolute;
}
.fieldsInfo {
  position: absolute;
  left: 103%;
  top: 0%;
  z-index: 2;
  display: none;
}
.cus-modal {
  z-index: 1;
  top: 100px;
  left: 30px;
  position: absolute;
  background-color: #e2f7f0;
  padding: 10px;
  /* display: none; */
}

/*
** table
*/

/*
** Theme Tabel
*/

.table th {
  background-color:#bfd1e5;
}

.sub-header .fa,
.table .fa {
  color: #a2a5b9;
  font-size: 14px;
  margin-right: 12px;
}
/* .table {
  background-color: rgb(235, 233, 235);
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  border: #1a1a27;
} */

.table .dropdown-icon {
  color: #595d6e;
  font-size: 15px;
  border-radius: 8px !important;
  margin-top: -7px;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #767e43cc;
}


.th-checkbox {
  max-width: 40px;
  padding: 0px;
}
table .checkbox,
th .checkbox {
  margin: 0;
  width: 40px;
  padding-top: 6px;
}


/*
** Process Tabel
*/
#processTable .table {
  color: #525256;
}
#processTable .table th {
  color: #8895a2;
  font-weight: 590;
  background-color:rgb(241, 169, 114);
  color:black;
}

#processTable .table .actions {
  text-align: center;
  width: 30px;
  background-color:rgb(241, 169, 114);
  color:black;
}

#processTable thead tr {
  background-color: #f3f6f9;
}
#processTable .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
#processTable .table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}


/* =========================================================== */

@media (min-width: 320px) and (max-width: 480px) {
  h1 {
    font-size: 14px;
  }
  #page-content-wrapper .container-fluid {
    padding: 0 5px;
  }
  .pageContent .container-fluid {
    padding: 0 5px;
  }
  .pageContent {
    padding: 2px;
  }
  .sub-header {
    margin-bottom: 18px;
    padding-left: 1px;
    padding-right: 1px;
  }

  #wrapper {
    padding-left: 250px;
  }
  .fixed-brand {
    width: 250px;
  }
  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }
  #sidebar-wrapper {
    overflow-y: scroll;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled-2 #sidebar-wrapper {
    width: 0px;
  }
  #wrapper.toggled-2 #sidebar-wrapper:hover {
    width: 250px;
  }
  #page-content-wrapper {
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-color: #f2f3f8;
    padding: 0;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
    padding-left: 250px;
  }
  #wrapper.toggled-2 #page-content-wrapper {
    position: relative;
    margin-right: 0;
    margin-left: -250px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: auto;
  }
  .tableWrapper {
    width: 100%;
    overflow-x: scroll;
  }
}
@media (min-width: 481px) and (max-width: 750px) {
  .tableWrapper {
    width: 100%;
    overflow-x: scroll;
  }
}
.progress-bar { 
  padding-bottom: 50px;
  height: 20px;  
  position: relative;
  background: rgb(3, 153, 28);
  border-radius: 25px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px rgba(2, 83, 130, 0.3);
}

@media (min-width: 751px) {
  #wrapper {
    padding-left: 250px;
  }
  .fixed-brand {
    width: 250px;
  }
  #wrapper.toggled {
    padding-left: 0;
  }
  #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled-2 #sidebar-wrapper {
    width: 50px;
  }
  #wrapper.toggled-2 #sidebar-wrapper:hover {
    width: 250px;
  }
  #page-content-wrapper {
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-color: #f2f3f8;
    padding: 0;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
    padding-left: 250px;
  }
  #wrapper.toggled-2 #page-content-wrapper {
    position: relative;
    margin-right: 0;
    margin-left: -200px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: auto;
  }
  .cus-modal1 {
    z-index: 1;
    top: 34px;
    left: 30px;
    position: absolute;
    background-color: #e2f7f0;
    border: solid 1px #039227;
    padding: 10px;
    /* display: none; */
    width: 890px;
  }
  .ScrollStyle
{
    max-height: 1000px;
    overflow-y: scroll;
}
.ScrollStyleModal
{
    max-height: 650px;
    overflow-y: scroll;
}
.bg-custom-1 {
  background-color: #f1e4e4!important;
}
.bg-custom-2 {
  background-color: #bfe3d9!important;
}
.bg-custom-3 {
  background-color:  #e1e79a!important;
}
.bg-custom-4 {
  background-color: #f5cfae!important;
}
.bg-custom-5{
  background-color: #edc9db!important;
}
.bg-custom-6 {
  background-color: f8f9fa!important;
}
.bg-custom-7 {
  background-color: #bbcb9b!important ;
}
.bg-custom-8 {
  background-color: hsl(166deg 72% 55% / 58%)!important;
  /* #e7e1b6!important; */
}
.bg-custom-9 {
  background-color: #e3e5ee!important; 
}


}
.modal-backdrop {
  position: inherit;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #ece4e4;
}
.multiInput{
  margin-bottom: -21px;
    z-index: 9;
    position: absolute;
}
