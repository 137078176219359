/* @import url(https://fonts.googleapis.com/css?family=Roboto); */
body,
input,
select,
textarea,
body * {
  /* font-family: "Roboto", sans-serif; */
  box-sizing: border-box;
}
body::after,
body::before,
input::after,
input::before,
select::after,
select::before,
textarea::after,
textarea::before,
body *::after,
body *::before {
  box-sizing: border-box;
}

/* body {
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e6e6e6);
  background-image: linear-gradient(top, #f2f2f2, #e6e6e6);
} */
/* 
h1 {
  font-size: 2rem;
  text-align: center;
  margin: 0 0 2em;
} */
/* 
.container {
  position: relative;
  max-width: 40rem;
  margin: 5rem auto;
  background: #fff;
  width: 100%;
  padding: 3rem 5rem 0;
  border-radius: 1px;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
  -webkit-transition: -webkit-transform 0.28s ease-in-out;
  transition: -webkit-transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out;
  transition: transform 0.28s ease-in-out, -webkit-transform 0.28s ease-in-out;
  z-index: -1;
}
.container:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
} */

form {
  margin: 0;
}
form .card-body {
  margin: 1.3rem 1rem 1rem 1rem;
  padding: 0;
}
.form-row {
  margin: 0;
  padding: 0;
}

fieldset {
  margin: 0 0 3rem;
  padding: 0;
  border: none;
}

.form-radio,
.form-checkbox {
  position: relative;
  margin-top: 1.1rem;
  /* margin-bottom: 2rem; */
}

.form-inline > .form-group,
.form-inline > .btn {
  display: inline-block;
  margin-bottom: 0;
}

.form-help {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: #b3b3b3;
  font-size: 0.8rem;
}

.checkbox .form-help,
.form-checkbox .form-help,
.form-radio .form-help,
.form-group .form-help,
.form-inline .form-help {
  position: relative;
  width: 100%;
}
.checkbox .form-help {
  position: relative;
  margin-bottom: 1rem;
}

.form-checkbox .form-help {
  padding-top: 0.25rem;
  margin-top: -1.5rem;
}
.form-radio .form-help {
  padding-top: 0.25rem;
  margin-top: -3.7rem;
}
/* .form-checkbox {
  margin-top: 1rem;
} */

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}
.form-group input {
  height: 1.9rem;
}
.form-group textarea {
  resize: auto;
}
.form-group select {
  width: 100%;
  font-size: 1rem;
  /* height: 1.9rem; */
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.9;
  box-shadow: none;
}
.form-group .control-label {
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: #b3b3b3;
  font-size: 0.9rem;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}
.form-group .bar {
  position: relative;
  border-bottom: 0.0625rem solid #c3c3c5;
  display: block;
}
.form-group .bar::before {
  content: "";
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #337ab7;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.form-group input,
.form-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 0.9rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  /* color: transparent; */
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}
.form-group input[type="file"] {
  line-height: 1;
}
.form-group input[type="file"] ~ .bar {
  display: none;
}
.form-group select,
.form-group input:focus,
.form-group input:valid,
.form-group input.form-file,
.form-group input.has-value,
.form-group textarea:focus,
.form-group textarea:valid,
.form-group textarea.form-file,
.form-group textarea.has-value {
  color: #333;
}
.form-group input:disabled {
  color: #ccc;
}

/*  */
/* 
  .form-group input:valid ~ .control-label,
 */
/*  */

.form-group input:placeholder-shown ~ .control-label {
  font-size: 0.7rem;
  color: #b3b3b3;
  top: -1.1rem;
}

.form-group textarea:not([value=""]) ~ .control-label,
.form-group textarea.focus ~ .control-label,
.form-group textarea.has-value ~ .control-label,
.form-group input:not([value=""]) ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group input.has-value ~ .control-label,
.form-group select ~ .control-label,
.form-group input.form-file ~ .control-label,
.raise-label .control-label {
  font-size: 0.7rem;
  color: #b3b3b3;
  top: -1.1rem;
}
.raise-label input[value=""] {
  color: #e0dede;
}
.form-group select:option[value=""] {
  color: red;
}
.form-group select ~ .control-label {
  left: 6px;
  font-size: 0.7rem;
  color: #b3b3b3;
  top: -1.1rem;
}

.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
}
.form-group select:focus ~ .control-label,
.form-group input:focus ~ .control-label,
.form-group textarea:focus ~ .control-label {
  color: #337ab7;
}
.form-group select:focus ~ .bar::before,
.form-group input:focus ~ .bar::before,
.form-group textarea:focus ~ .bar::before {
  width: 100%;
  left: 0;
}

.checkbox label,
.form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 1.4rem;
  text-align: left;
  color: #333;
  display: block;
}
.checkbox input,
.form-radio input {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
}

.radio {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.radio .helper {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #dcdfea;
  background-color: #ebedf4;
}
.radio .helper::before,
.radio .helper::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor;
}
.radio .helper::after {
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #337ab7;
  border-color: #337ab7;
}

.radio label:hover .helper,
.radio label:focus .helper {
  color: #337ab7;
}
.radio input:focus ~ .helper::after {
  outline: 30px auto #337ab7;
}
.radio input:focus ~ .helper::before {
  outline: 30px auto #337ab7;
}
.radio input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.radio input:checked ~ .helper::before {
  color: #337ab7;
}

.checkbox {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.checkbox .helper {
  color: #dcdfea;
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  border-radius: 0.0725rem;
  -webkit-transition: border-color 0.28s ease;
  transition: border-color 0.28s ease;
  background-color: #ebedf4;
}
.checkbox .helper::before,
.checkbox .helper::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: #337ab7;
  display: block;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: "";
  -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0;
}
.checkbox .helper::before {
  top: 0.65rem;
  left: 0.38rem;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.0625rem #fff;
}
.checkbox .helper::after {
  top: 0.3rem;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.checkbox label:hover .helper {
  color: #337ab7;
}
.checkbox input:focus ~ .helper {
  color: #337ab7;
}
.checkbox input:checked ~ .helper {
  color: #337ab7;
}
.checkbox input:checked ~ .helper::after,
.checkbox input:checked ~ .helper::before {
  opacity: 1;
  -webkit-transition: height 0.28s ease;
  transition: height 0.28s ease;
}
.checkbox input:checked ~ .helper::after {
  height: 0.5rem;
}
.checkbox input:checked ~ .helper::before {
  height: 1.2rem;
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 1rem;
}
.form-inline {
  padding-left: 5px;
}
.form-inline .checkbox {
  margin-top: 1rem;
}

.form-inline .radio {
  margin-top: -0.5rem;
}
.form-inline .radio,
.form-inline .checkbox {
  margin-right: 14px;
}

.has-error .legend.legend,
.has-error.form-group .control-label.control-label {
  color: #d9534f;
}
.has-error.form-group .form-help,
.has-error.form-group .helper,
.has-error.checkbox .form-help,
.has-error.checkbox .helper,
.has-error.radio .form-help,
.has-error.radio .helper,
.has-error.form-radio .form-help,
.has-error.form-radio .helper {
  color: #d9534f;
}
.has-error .bar::before {
  background: #d9534f;
  left: 0;
  width: 100%;
}

option {
  padding: 20px;
}

.signin-block {
  margin-top: 50px;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
/* .form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
    */

.form-signin .form-group {
  padding: 0;
}

.form-no-header {
  margin-top: 0;
}

.button-container {
  text-align: center;
}

.button {
  position: relative;
  background: currentColor;
  border: 1px solid currentColor;
  border-radius: 4px;
  font-size: 1rem;
  color: #53abf1;
  margin: 1rem 0;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  -webkit-transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.button1 {
  position: relative;
  background: red;
  border: 1px solid currentColor;
  border-radius: 4px;
  font-size: 1rem;
  color: #ffff;
  margin: 1rem 0.5rem;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  cursor: pointer;
  -webkit-transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  transition: background-color 0.28s ease, color 0.28s ease,
    box-shadow 0.28s ease;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.buttonReset {
  background: #999;
}

.button span {
  color: #fff;
  position: relative;
  z-index: 1;
}
.button::before {
  content: "";
  position: absolute;
  background: #071017;
  border: 50vh solid #1d4567;
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 0;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.button:hover {
  color: #337ab7;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.button:active::before,
.button:focus::before {
  -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s,
    -webkit-transform 1.12s ease;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}
.button:focus {
  outline: none;
}



.form-group .alert {
  padding: 2px 10px;
  position: absolute;
  display: flex;
  z-index: 2;
  margin: 0;
  /* margin-right: 20px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  right: 0;
}
.form-radio .alert {
  margin-top: 24px;
}
.loginForm .alert,
.form-row .alert {
  right: 15px;
}
.form-row .alert {
  right: 5px;
}

#loginWrapper {
  width: 300px;
  margin: auto auto;
  margin-top: 5em;
  height: 550px;
}

.loginContainer {
  margin-top: 80px;
}
.loginContainer .h2 {
  color: #b2b4c0;
}
.loginContainer .lead {
  color: #838699;
}
.loginBtn {
  margin-top: 30px !important;
}
.loginForm {
  margin-top: 40px !important;
}
.loginForm .form-group {
  margin-top: 2rem;
}
.mainForm {
  padding-top: 20px;
}

.form-subHead {
  color: #56a286;
  background-color: #edfdf7;
  border: solid 1px #d6eae3;
  border-radius: 7px;
  padding: 8px;
  margin-left: -8px;
  margin-right: -8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.form-subHeader {
  color: #93949a;
  background-color: #e3e5ee;
  border: solid 1px #d6eae3;
  border-radius: 7px;
  padding: 8px;
  margin-left: -8px;
  margin-right: -8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

form hr {
  background-color: #a7f98a;
  margin-bottom: 1.5rem;
  margin-left: -8px;
  margin-right: -8px;
}

hr {
  background-color: #a7f98a;
}

.btn {
  margin-right: 10px;
}

.lib-drop-down-menu {
  margin: 0px;
  font-weight: 400;
  /* border: solid 1px #000; */
  padding: 0.01rem 0.5rem;
  line-height: 1rem;
  color: #ccc;
  font-size: 0.9rem;
}
.dropdown-menu .dropdown-item {
  font-size: 0.8rem;
  padding: 0.25rem 0.2rem 0.25rem 0.7rem;
}
.dropdown-item i {
  margin-right: 6px !important;
  color: #b1b1b2;
}
.dropdown-item:hover {
  background-color: #dbebfd;
}
.dropdown-menu a {
  padding: 0.25rem 0.2rem 0.25rem 0.7rem !important;
}

#setting {
  border: dashed 1px #d6eae3;
  padding-bottom: 10px;
  margin-top: -20px;
}
.btn-group .btn {
  margin-right: 0px;
}

/* //////AKSHAY/////// */

.modal-dialog-full-width {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content-full-width {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
}

.modal-header-full-width {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width {
  border-top: 1px solid #9ea2a2 !important;
}

#dstAddressHeading, #srcAddressHeading, #boxDetailsHeading {
  cursor: pointer;
  color: #6c757d;
  background-color: white;
}

#dstAddressHeading:hover,
#srcAddressHeading:hover,
#boxDetailsHeading:hover {

  background-color: #471eecbe;
  color: white;

}
#dstAddressHeading.toggleInput, #srcAddressHeading.toggleInput, #boxDetailsHeading.toggleInput {
  background-color: #471eec;
    color: white;
}


.srcAddressInputs, .dstAddressInputs, .boxLevelInputs {
  display: none
}

/* #srcAddressHeading:hover+.srcAddressInputs,
#dstAddressHeading:hover+.dstAddressInputs {
  display: flex;
  transition: transform 0.28s ease-in-out;
} */

.toggleInput+.srcAddressInputs, .toggleInput+.dstAddressInputs, .toggleInput+.boxLevelInputs {
  display: flex;
  transition: transform 0.28s ease-in-out;
}

.shipmentNo .edit-shipmentNo {
  display: none;
  cursor: pointer;
}

.shipmentNo:hover .edit-shipmentNo {
  display: inline;
}

.shipmentNo:hover .edit-shipmentNo:hover {
  color: rgb(25, 104, 1);
}

.shipmentNo .shipmentNoInput {
    display: none;
}
.shipmentNo:hover .shipmentNoInput {
    display: block;
}

.shipmentNoInputManifest {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid black;
}