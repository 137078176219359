/* .swal-wide{
    width:1000px !important;
} */

.sweet-alert.sweetalert-lg { width: 1000px; }

.icon-badge-group {
 
}

.icon-badge-group .icon-badge-container {
    display: inline-block;
    margin-left:15px;
}

.icon-badge-group .icon-badge-container:first-child { 
  margin-left:0
}

.icon-badge-container {
    margin-top:20px;
    position:relative;
}

.icon-badge-icon {
    font-size: 10px;
    position: relative;
}

.icon-badge {
    background-color: red;
    font-size: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
    width:15px;
    height:15px;
    border-radius: 35%;
    position: absolute; /* changed */
    top: -25px; /* changed */
    left: 1px; /* changed */
}