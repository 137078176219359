.co-pageContent {
  padding: 3px;
}
.co-pageContent .container-fluid {
  padding: 2px;
}
#coAction {
  padding-left: 0 !important;
}

#cocard {
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
  align-items: center;

}
#cocard:hover {
  overflow-y: scroll;
}

#cocard::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
#cocard:hover::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#cocard::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f5f5f5;
  border-radius: 10px;
}
#cocard:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#cocard::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 50px;
}
#cocard:hover::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
  border-radius: 50px;
}

#cocards {
  overflow-y: hidden;
  /* position: relative; */
  overflow-x: hidden;
  align-items: center;
}
#cocards:hover {
  overflow-y: hidden;
}

#cocards::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
} 
 #cocards:hover::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#cocards::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f5f5f5;
  border-radius: 10px;
}
#cocards:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#cocards::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 50px;
}
#cocards:hover::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
  border-radius: 50px;
}

#cocardsFe {
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
  align-items: center;
}
#cocardsFe:hover {
  overflow-y: scroll;
}

#cocardsFe::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
#cocardsFe:hover::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#cocardsFe::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f5f5f5;
  border-radius: 10px;
}
#cocardsFe:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#cocardsFe::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 50px;
}
#cocardsFe:hover::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(107, 128, 193))
  );
  border-radius: 50px;
}

.cursorPointor {
  cursor: pointer;
}


#cocardHistory {
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
  align-items: center;

}
#cocardHistory:hover {
  overflow-y: scroll;
}

#cocardHistory::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
#cocardHistory:hover::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#cocardHistory::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f5f5f5;
  border-radius: 10px;
}
#cocardHistory:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#cocardHistory::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 50px;
}
#cocardHistory:hover::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
  border-radius: 50px;
}
/* #coTicketList {
  left: 0;
  top: 0;
  position: relative;
} */
