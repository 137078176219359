.row {
    --bs-gutter-x: 0rem !important;
}
.card {
    border: 0;
    background: #f4f5fb;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 2rem;
    box-shadow: none;
    width: 100%;
}

.inbox-categories {
  width: 100%;
  padding: 0 20px;
  margin-left: 0;
}

.inbox-categories button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-right: 1px solid rgba(0,0,0,0.1);
  background-color: rgba(255,255,255,0.75);
  cursor: pointer;
  font-weight: 700;
}
.tabcontent {
    display: none;
    border-top: none;
  }

.inbox-categories button:nth-child(1) {
  color: #2da9e9;
  border-right-color: rgba(45,129,233,0.06);
  border-bottom: 4px solid #2da9e9;
  border-top-left-radius: 5px;
}

.inbox-categories button:nth-child(1).active{
  color: #fff;
  background-color: #2da9e9;
  border-bottom: 4px solid rgba(0,0,0,0.15);
}

.inbox-categories button:nth-child(2) {
  color: #0ec8a2;
  border-right-color: rgba(14,200,162,0.06);
  border-bottom: 4px solid #0ec8a2;
}

.inbox-categories button:nth-child(2).active {
  color: #fff;
  background-color: #0ec8a2;
  border-bottom: 4px solid rgba(0,0,0,0.15);
}

.inbox-categories button:nth-child(3) {
  color: #ff9e2a;
  border-right-color: rgba(255,152,14,0.06);
  border-bottom: 4px solid #ff9e2a;
}

.inbox-categories button:nth-child(3).active {
  color: #fff;
  background-color: #ff9e2a;
  border-bottom: 4px solid rgba(0,0,0,0.15);
}

.inbox-categories button:nth-child(4) {
  color: #314557;
  border-bottom: 4px solid #314557;
  border-right-color: transparent;
}

.inbox-categories button:nth-child(4).active {
  color: #fff;
  background-color: #314557;
  border-bottom: 4px solid rgba(0,0,0,0.35);
}

.chat-search-box {
    -webkit-border-radius: 2px 0 0 0;
    -moz-border-radius: 2px 0 0 0;
    border-radius: 2px 0 0 0;
    padding: .75rem 1rem;
}

 .chat-search-box .input-group .form-control {
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    border-right: 0;
}

 .chat-search-box .input-group .form-control:focus {
    border-right: 0;
}
 
 .chat-search-box .input-group .input-group-btn .btn {
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
    margin: 0;
}

 .chat-search-box .input-group .input-group-btn .btn i {
    font-size: 1.2rem;
    line-height: 100%;
    vertical-align: middle;
}



.users-container {
    position: relative;
    padding: 1rem 0;
    border-right: 1px solid #e6ecf3;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}


.users {
    padding: 0;
}

.users .person {
    position: relative;
    width: 100%;
    padding: 10px 1rem;
    cursor: pointer;
    border-bottom: 1px solid #f0f4f8;
}

.users .person.active-user {
    background-color: #ffffff;
   background-image: -webkit-gradient (linear, left top, left bottom, from(#f7f9fb), to(#ffffff));
    background-image: -webkit-linear-gradient(right, #f7f9fb, #ffffff);
    background-image: -moz-linear-gradient(right, #f7f9fb, #ffffff);
    background-image: -ms-linear-gradient(right, #f7f9fb, #ffffff);
    background-image: -o-linear-gradient(right, #f7f9fb, #ffffff);
    background-image: linear-gradient(right, #f7f9fb, #ffffff);
}

.users .person:last-child {
    border-bottom: 0;
}

.users .person .user {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.users .person .user img {
    width: 48px;
    height: 48px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.users .person .user .status {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background: #e6ecf3;
    position: absolute;
    top: 0;
    right: 0;
}

.users .person .user .status.online {
    background: #9ec94a;
}

.users .person .user .status.offline {
    background: #c4d2e2;
}

.users .person .user .status.away {
    background: #f9be52;
}

.users .person .user .status.busy {
    background: #fd7274;
}

.users .person p.name-time {
    font-weight: 600;
    font-size: .85rem;
    display: inline-block;
}

.users .person p.name-time .time {
    font-weight: 400;
    font-size: .7rem;
    text-align: right;
    color: #8796af;
}

.selected-user {
    width: 100%;
    padding: 0 15px;
    min-height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #e6ecf3;
    border-radius: 0 0 5px 0;
}


.selected-user span {
    line-height: 100%;
}

.selected-user span.name {
    font-weight: 700;
}

.chat-container {
    position: relative;
    padding: 1rem;
    
}

.chat-container li.chat-left,
.chat-container li.chat-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 30px;
}

.chat-container li img {
    width: 48px;
    height: 48px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.chat-container li .chat-avatar {
    margin-right: 20px;
}

.chat-container li.chat-right {
    justify-content: flex-end;
}

.chat-container li.chat-right > .chat-avatar {
    margin-left: 20px;
    margin-right: 0;
}

.chat-container li .chat-name {
    font-size: .75rem;
    color: #999999;
    text-align: center;
}

.chat-container li .chat-text {
    padding: .4rem 1rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    /* font-weight: 300;
    line-height: 150%; */
    position: relative;
}

.chat-container li .chat-text:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: -20px;
    border: 10px solid;
    border-color: transparent #ffffff transparent transparent;
}

.chat-container li.chat-right > .chat-text {
    text-align: right;
}

.chat-container li.chat-right > .chat-text:before {
    right: -20px;
    border-color: transparent transparent transparent #ffffff;
    left: inherit;
}

.chat-container li .chat-hour {
    padding: 0;
    margin-bottom: 10px;
    font-size: .75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 15px;
}

.chat-container li .chat-hour > span {
    font-size: 16px;
    color: #9ec94a;
}

.chat-container li.chat-right > .chat-hour {
    margin: 0 15px 0 0;
}


.chat-form {
    padding: 15px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid white;
}
.chat-footer {
    position: relative;
    width: 100%;
    padding: 20px;
  }
  
  .chat-footer .send-message-text {
    position: relative;
    display: block;
    width: 100%;
    min-height: 55px;
    max-height: 75px;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 95px 5px 10px;
    font-size: 13px;
    resize: vertical;
    outline: none;
    border: 1px solid #e0e6eb;
  }
  
  .chat-footer .send-message-button {
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    right: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 1px solid rgba(0,0,0,0.05);
    outline: none;
    font-weight: 600;
    border-radius: 50%;
    padding: 0;
  }
  
  .chat-footer .send-message-button > i {
    font-size: 16px;
    margin: 0 0 0 -2px;
  }
  
  .chat-footer label.upload-file input[type="file"] {
    position: fixed;
    top: -1000px;
  }
  
  .chat-footer .upload-file {
    display: block;
    position: absolute;
    right: 150px;
    height: 30px;
    font-size: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.25;
  }
  
  .chat-footer .upload-file:hover {
    opacity: 1;
  }

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 767px) {
    .chat-search-box {
        display: none;
    }
}
@media (max-width: 767px) {
    .chat-container li.chat-left,
    .chat-container li.chat-right {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .chat-container li img {
        width: 32px;
        height: 32px;
    }
    .chat-container li.chat-left .chat-avatar {
        margin: 0 0 5px 0;
        display: flex;
        align-items: center;
    }
    .chat-container li.chat-left .chat-hour {
        justify-content: flex-end;
    }
    .chat-container li.chat-left .chat-name {
        margin-left: 5px;
    }
    .chat-container li.chat-right .chat-avatar {
        order: -1;
        margin: 0 0 5px 0;
        align-items: center;
        display: flex;
        justify-content: right;
        flex-direction: row-reverse;
    }
    .chat-container li.chat-right .chat-hour {
        justify-content: flex-start;
        order: 2;
    }
    .chat-container li.chat-right .chat-name {
        margin-right: 5px;
    }
    .chat-container li .chat-text {
        font-size: .8rem;
    }
}


textarea#styled {
	width: 600px;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
	font-family: Tahoma, sans-serif;
	/* background-image: url(bg.gif); */
	background-position: bottom right;
	background-repeat: no-repeat;
}
