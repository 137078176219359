.border-one {
    height: 100%;
    width: 100%;
    border-style: solid;
    border-width: 1px;
}
.border-two {
    border-left:solid;            
    border-width: 1px;
}

.text-center {
    text-align: center;
}